
import axios, { AxiosError } from "axios";
import { PropType, Ref, onBeforeUnmount, ref, watch } from "vue";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import useAlert from "@/composables/Alert";
import { useLoaderStore } from "@/store/LoaderStore";
import moment from 'moment';
import ModalInvoiceWarning from "./ModalInvoiceWarning.vue";

export interface IIvoiceItems {
  name: string;
  status: string;
  id: number;
  sku: string;
  invoiceQuantity: number;
  stockQuantity: number;
  currentQuantity: number;
}

export default defineComponent({
  name: "StockInfo",
  emits: [],
  components: {
    ModalInvoiceWarning
  },
  props: {
    openClose: Boolean,
    detailList: Object,
    handleGetStock: {
      type: Function as PropType<() => void>,
      required: true,
    }
  },
  setup(props) {
    const dialogVisible = ref(false)
    const stores = ref<any>()
    const detailProduct = ref()
    const route = useRoute()
    const { id: id_store } = route.params
    const eventClose: Ref<HTMLElement | undefined> = ref()
    const isOut = ref(false)
    const timer:Ref<any> = ref(null)
    const detailList = ref()
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const toggleModalDel = ref(false)
    const invoiceItems = ref<IIvoiceItems[]>([])

    function closeModalClickOut(e) {
      if(e.target.className === "container_modal" || e.target.className === "container_buttonClose" ) {
        if(dialogVisible.value) {
        isOut.value = true  
        timer.value = setTimeout(() => {
          isOut.value = false
          dialogVisible.value = false

        }, 205)
        
      } 
      }
    }

    const clearTimeoutFn = () => {
      clearTimeout(timer.value);
    };

    async function handleInvoiceCancellation() {
      loaderStore.open()
      try {
        const { movement_id } = detailList.value
        const result = await axios.post(`/api/postStock`, {
          movement_id,
          status: "cancelado"
        })
        showTimeAlert(result.data.message)
        props.handleGetStock()
        dialogVisible.value = false
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
      }
    }

    function handleModalInfo(){
      toggleModalDel.value = !toggleModalDel.value
      const { movement_id } = detailList.value
      checkInvoiceStock(movement_id)
    }

    async function checkInvoiceStock(movement_id: number | string) {
      try{
        const response = (await axios.get(`/api/checkInvoiceStock?movement_id=${movement_id}`)).data
        invoiceItems.value = response.data
      }
      catch (error){
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      }
    }

    // Limpar o timeout quando o componente for desmontado
    onBeforeUnmount(clearTimeoutFn);


    watch(() => props.detailList, () => {
      detailList.value = new Object(props.detailList)
    })

    watch(() => props.openClose, () => {
          // isOut.value = false
          // dialogVisible.value = !dialogVisible.value
          dialogVisible.value = !dialogVisible.value

    })
    
    return {
      dialogVisible,
      eventClose,
      closeModalClickOut,
      isOut,
      detailList,
      handleInvoiceCancellation,
      toggleModalDel,
      moment,
      handleModalInfo,
      invoiceItems,
    }
  },
});
